










import { Component, Vue } from 'vue-property-decorator'
import { VueApolloComponentOptions } from 'vue-apollo/types/options'
import { BTable } from 'bootstrap-vue'
import { SubscribeOptions } from '@/core/subscribeHelpers'
import { terminalService } from '@/store/terminalService'
import {
  terminalLogAdded,
  TerminalLogAddedSubscription,
  TerminalLogAddedSubscriptionVariables,
  terminalLogDeleted,
  TerminalLogDeletedSubscription,
  TerminalLogDeletedSubscriptionVariables,
  terminalLogs as terminalLogsDoc,
  TerminalLogsQuery,
  TerminalLogsQueryVariables,
  terminalLogUpdated,
  TerminalLogUpdatedSubscription,
  TerminalLogUpdatedSubscriptionVariables
} from '@/graphql/default/terminalLogs.graphql'

@Component({
  apollo: {
    terminalLogs: {
      query: terminalLogsDoc,
      variables(this:TerminalLog) {
        return this.variables
      }
    }
    // eslint-disable-next-line no-use-before-define
  } as VueApolloComponentOptions<TerminalLog>,
  components: { BTable }
})
export default class TerminalLog extends Vue {
  terminalLogs?: TerminalLogsQuery['terminalLogs']

  get logs() {
    return this.terminalLogs?.edges.map((value) => {
      const log = Object.assign({}, value?.node)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete log.__typename
      return log
    })
  }

  variables: TerminalLogsQueryVariables = {
    first: 3,
    orderBy: ['createdAt']
  }

  async fetch(): Promise<void> {
    const mac = await terminalService.getMAC()
    const variables = {
      mac
    }
    const { terminalLogs } = this.$apollo?.queries
    terminalLogs.subscribeToMore(new SubscribeOptions<TerminalLogsQuery,
        TerminalLogAddedSubscription,
        TerminalLogAddedSubscriptionVariables,
      TerminalLogsQueryVariables>({
        type: 'added',
        subscribeDocument: terminalLogAdded,
        queryDocument: terminalLogsDoc,
        variables,
        queryVariables: this.variables
      }))
    terminalLogs.subscribeToMore(new SubscribeOptions<TerminalLogsQuery,
        TerminalLogUpdatedSubscription,
        TerminalLogUpdatedSubscriptionVariables,
      TerminalLogsQueryVariables>({
        type: 'updated',
        subscribeDocument: terminalLogUpdated,
        queryDocument: terminalLogsDoc,
        variables,
        queryVariables: this.variables
      }))
    terminalLogs.subscribeToMore(new SubscribeOptions<TerminalLogsQuery,
        TerminalLogDeletedSubscription,
        TerminalLogDeletedSubscriptionVariables,
      TerminalLogsQueryVariables>({
        type: 'deleted',
        subscribeDocument: terminalLogDeleted,
        queryDocument: terminalLogsDoc,
        variables,
        queryVariables: this.variables
      }))
  }

  created(): void {
    this.fetch()
  }
}
